import { useTranslation } from 'react-i18next';
import { Zoom } from "react-awesome-reveal";
import img from "../img/hippo2.webp";
import img2 from "../img/tucnak1.webp";
import WavyBackground from "./WavyBackground";
import { useState } from 'react';

// Wave Divider Component
const WaveDivider = () => (
    <svg className="w-full" viewBox="0 50 1440 220">
        <path fill="#BCE7FD" fillOpacity="1" d="M0,160L48,144C96,128,192,96,288,101.3C384,107,480,149,576,165.3C672,181,768,171,864,144C960,117,1056,75,1152,69.3C1248,64,1344,96,1392,112L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
    </svg>
);

// U-Shape Divider Component
const UShapeDivider = () => (
    <svg className="w-full" viewBox="0 0 1440 320">
        <path fill="#FFFFFF" fillOpacity="1" d="M0,128L120,144C240,160,480,192,720,192C960,192,1200,160,1320,144L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
    </svg>
);

// V-Shape Divider Component
const VShapeDivider = () => (
    <svg className="w-full" viewBox="0 0 1440 320">
        <path fill="#eeeeee" fillOpacity="1" d="M0,96L720,320L1440,96L1440,0L720,0L0,0Z"></path>
    </svg>
);

const Hero = () => {
    const { t } = useTranslation();
    const [isHoveredHippo, setIsHoveredHippo] = useState(false);
    const [isHoveredNinja, setIsHoveredNinja] = useState(false);

    return (
        <div id="home" className="hero min-h-screen flex flex-col justify-center items-center bg-cover bg-center relative overflow-hidden bg-gradient-to-b from-white to-sky-200 dark:bg-gradient-to-b dark:from-gray-900 dark:to-blue-900">
            <WavyBackground colors={["#6fc3e8", "#26A5E3", "#C7EAFE"]} waveWidth={30}>
                <Zoom triggerOnce={true}>
                    <h1 className="flex flex-col font-bold mb-8 font-gluten dark:text-white text-center relative">
                        <span className="text-[2.5rem] pb-1">{t('welcomeMessage')}</span>
                        <div>
                            <span
                                className="text-6xl sm:text-7xl font-gluten text-blue-900 dark:text-white">Little</span>
                            <span
                                className="font-kalam text-4xl sm:text-5xl transform bg-gradient-to-r from-sky-400 to-sky-600 inline-block text-transparent bg-clip-text dark:bg-gradient-to-r dark:from-blue-400 dark:to-blue-600"
                                style={{display: 'inline-block', transform: 'skewY(-10deg)'}}>Club</span>

                        </div>
                        <span className="text-md text-gray-600 dark:text-white">Plavání a cvičení pro děti</span>
                    </h1>
                    <div
                        className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 items-center relative">
                        <div className="relative flex items-center">
                            <img
                                className={`absolute -left-6 transform -translate-x-full h-auto max-w-[80px] sm:max-w-[100px] md:max-w-[120px] lg:max-w-[150px] max-h-[80px] sm:max-h-[100px] md:max-h-[120px] lg:max-h-[300px] transition-opacity duration-300 ${isHoveredHippo ? 'opacity-100' : 'opacity-0'} hidden lg:block`}
                                src={img} alt="hippo" />
                            <div
                                className="relative max-w-xs p-6 bg-sky-300 rounded-full shadow-lg text-center cursor-pointer transform transition duration-300 hover:scale-105 hover:bg-sky-400"
                                onMouseEnter={() => setIsHoveredHippo(true)}
                                onMouseLeave={() => setIsHoveredHippo(false)}>
                                <a href="#plavaniprodeti">
                                    <h2 className="text-2xl font-semibold font-gluten break-words">{t('littleSwimTitle')}</h2>
                                    <p className="text-sm mt-2 font-varela break-words">{t('littleSwimDescription')}</p>
                                </a>
                            </div>
                        </div>
                        <div className="relative flex items-center">
                            <div
                                className="relative max-w-xs p-6 bg-amber-200 rounded-full shadow-lg text-center cursor-pointer transform transition duration-300 hover:scale-105 hover:bg-amber-300"
                                onMouseEnter={() => setIsHoveredNinja(true)}
                                onMouseLeave={() => setIsHoveredNinja(false)}>
                                <a href="#cviceniprodeti">
                                    <h2 className="text-2xl font-semibold font-gluten break-words">{t('littleNinjasTitle')}</h2>
                                    <p className="text-sm mt-2 font-varela break-words">{t('littleNinjasDescription')}</p>
                                </a>
                            </div>
                            <img
                                className={`absolute -right-6 transform translate-x-full h-auto max-w-[80px] sm:max-w-[100px] md:max-w-[120px] lg:max-w-[150px] max-h-[80px] sm:max-h-[100px] md:max-h-[120px] lg:max-h-[300px] transition-opacity duration-300 ${isHoveredNinja ? 'opacity-100' : 'opacity-0'} hidden lg:block`}
                                src={img2} alt="ninja" />
                        </div>
                    </div>
                </Zoom>
            </WavyBackground>
        </div>
    );
};

export default Hero;
